import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

function CallToAction({ title, message, styles, innerstyles, children }) {

	console.log(innerstyles);
	
	return(
		<div style={styles}>
			<Inner style={innerstyles}>
				{title ? <h2 className="title">{title}</h2> : ''}
				{message ? <p>{message}</p> : '' }
				<ContentWrapper>
					{children}
				</ContentWrapper>
			</Inner>
	   </div>
	);
}

CallToAction.propTypes = {
    styles: PropTypes.object,
	innerstyles: PropTypes.object,
}
  
CallToAction.defaultProps = {
	styles: {},
    innerstyles: {maxWidth: `1000px`},
}

const Inner = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
    align-items: center;
	padding: 40px 20px;
	color: #fff;
`

const ContentWrapper = styled.div`
	margin: 20px 0;
`

export default CallToAction