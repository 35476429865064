import React from "react";
import CallToAction from "../../components/CallToAction";
import LinkButton from "../../components/LinkButton";
import BgImage from '../../images/banner-images/portfolio-banner.jpg'

function PortfolioSection() {

    return (
        <CallToAction 
            styles={{
                background: `url(${BgImage}) no-repeat`,
                backgroundSize: `cover`,
                backgroundPosition: `50% 60%`,
                padding: `80px 0`,
            }} 
            innerstyles={{
                maxWidth: `800px`,
                color: `#272f32`,
                backgroundColor: `rgba(255, 255, 255, .9) `,
                alignItems: `start`,
                padding: `40px 30px`,
                margin: `0 0 0 80px`,
            }} 
            title="View Projects"
            message="Take a look at websites and web apps that ThowardMedia have completed."
            >
            <LinkButton text="View portfolio" link="/portfolio"/> 
        </CallToAction>   
    )
}

export default PortfolioSection;
