import React from "react";
import styled from 'styled-components';

function LinkButton(props) {
    return (
        <Btn href={props.link} className={props.className}>{props.text}</Btn>
    );

}

export default LinkButton;

const Btn = styled.a`
    margin: 20px 10px;
    color: #fff;
    padding: 10px 15px;
    font-size: 18px;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: red;
    border: 1px solid red;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &:hover {
        text-decoration: none;
        cursor: pointer;
        color: red;
        border: 1px solid red;
        background-color: #fff;
    }
`