import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class SliderItem extends Component {
  render() {

    const { className, children,  ...other } = this.props;

    return (
        <li className={`slider-item ${className}`} {...other}>
            {children}
        </li>      
    );
  }
}

export default SliderItem;


SliderItem.propTypes = {
  className: PropTypes.string
};

SliderItem.defaultProps = {
  className: ''
};