import React from "react";
import LinkButton from "../../components/LinkButton";
import CallToAction from "../../components/CallToAction";

function ContactSection() {

    return (
        <CallToAction title="Get in touch" message="Get in contact today via the messenger or click one of the buttons below.">
            <LinkButton text="Get in touch" link="/contact"/> 
            <LinkButton text="Request an estimate" link="/request-estimate"/> 
        </CallToAction>   
    )
}

export default ContactSection;