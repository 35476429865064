import React from "react";
import styled from 'styled-components';

import GatsbyLogo from '../../images/logos/gatsby-logo.png';
import DrupalLogo from '../../images/logos/drupal-logo.png';
import LaravelLogo from '../../images/logos/laravel-logo.png';

function TechSection() {

    return (
        <Wrapper>
        <h2 className="title">Technologies ThowardMedia specialise in...</h2>     

        <LogoWrapper>
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
            <img src={GatsbyLogo} alt="Gatsby JS" />
            </a>

            <a href="https://www.drupal.org/" target="_blank" rel="noreferrer">
            <img src={DrupalLogo} alt="Drupal" />
            </a>

            <a href="https://www.laravel.com/" target="_blank" rel="noreferrer">
            <img src={LaravelLogo} alt="Laravel" />
            </a>
        </LogoWrapper>
      </Wrapper>
    )
}

export default TechSection;

const Wrapper = styled.section`
    padding: 60px 0;
` 

const LogoWrapper = styled.div`
  display: flex;
  max-width: 900px;
  margin: 40px auto 0 auto;

  a {
    margin: 0 30px;
    display: flex;

    &:hover {
        img {
            filter: grayscale(90%);
        }
    }

    img {
        filter: grayscale(0%);
    }
  }
`