import React from "react";
import styled from "styled-components";
import LinkButton from "../../components/LinkButton";

function Services(props) {
    const { services, layout } = props;

    return (
        <>

        <ServiceWrapper className={layout}> 

            {services.map(({ node }) => {
                return (
                <div className="service"  key={node.drupal_internal__nid}>
                    <i className={`fa fa-${node.field_icon} service-icon`}></i>
                    <h3>{node.title}</h3>
                    <p>{node.field_teaser_summary}</p>
                </div>
                )
            }
            )}

          <LinkButton className="linkbtn" link="/services/" text="Find out more" />

        </ServiceWrapper>
        </>
    );
}
       

export default Services;

const ServiceWrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media(min-width: 768px) {
    flex-direction: row;
  }

  .linkbtn {
    margin: 40px auto;
  }

  &.page {
      .service {
        flex: 1 0;

        @media(min-width: 600px) {
            flex: 1 0 45%;
            margin: 10px;
          }
      
          @media(min-width: 990px) {
            flex: 1 0 45%;
          }
      }
  }

  .service {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 30px;
    color: #272f32;
    background-color: white;
    border: 2px solid #272f32;

    @media(min-width: 600px) {
      flex: 1 0 45%;
      margin: 10px;
    }

    @media(min-width: 990px) {
      flex: 1 0 22%;
    }

    .service-icon {
        margin-bottom: 15px;
        color: red;

        &:before {
            font-size: 24px;
        }
      }

    h3 {
      text-transform: uppercase;
      font-size: 21px;
      min-height: 46px;
      color: #424f52;
    }

    p {
      font-size: 16px;
    }
  }
`